import './index.scss'

$(document).scroll(function () { 
    var scroH = $(document).scrollTop();     
    var viewH = $(window).height();         
    var contentH = $(document).height();           
    if(scroH >50){
        $('header').addClass('scroll-header')
    }else{
        $('header').removeClass('scroll-header')
    }    
    if (contentH - (scroH + viewH) <= 100){                 
    }           
    if (contentH = (scroH + viewH)){                       
    }
});


$('[data-open="cmenu"]').on('click', function () {
    if ($('.header .hamburger').hasClass('active')) {
        $('.header .hamburger').removeClass('active')
        $('.menu-mob').removeClass('show')
        $('.menu-mob ul li .sub-menu').slideUp(200)
        $('.index-header .header').css('background-color','rgba(0,0,0,0)')
    } else {
        $('.header .hamburger').addClass('active')
        $('.menu-mob').addClass('show')
        $('.index-header .header').css('background-color', '#d3e9f0')
    }
})

$('.menu-mob ul li .menu-title').on('click',function () {
    let fatherEl = $(this).parents('li')
    if(!$(fatherEl).hasClass('active')){
        $(fatherEl).addClass('active').siblings().removeClass('active')
        $(fatherEl).find('.sub-menu').slideDown(200)
        $(fatherEl).siblings().find('.sub-menu').slideUp(200)
    }else{
        $(fatherEl).removeClass('active').find('.sub-menu').slideUp(200)
    }
})
